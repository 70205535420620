import { useEffect, useCallback, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./SectionManager.scss";
import { getPageData } from "../../store/index.service";
import { StoreContext } from "../../store/store";
import { Helmet } from "react-helmet";

//Importation des sections
import { LegalMention } from "../../sections/legalMention/LegalMention";
import { Hero } from "../../sections/hero/Hero";
import { TextWithAction } from "../../sections/textWithAction/TextWithAction";
import { Frieze } from "../../sections/frieze/Frieze";
import { LatestNews } from "../../sections/latestNews/LatestNews";
import { Video } from "../../sections/video/Video";
import { OurStory } from "../../sections/ourStory/OurStory";
import { PageList } from "../../sections/pageList/PageList";
import { WorldMap } from "../../sections/worldMap/WorldMap";
import { DataTable } from "../../sections/dataTable/DataTable";
import { Carousel } from "../../sections/carousel/Carousel";
import { Article } from "../../sections/article/Article";
import { Text } from "../../sections/text/Text";
import { Picture } from "../../sections/picture/Picture";
import { Mozaic } from "../../sections/mozaic/Mozaic";
import { WhyJoinUs } from "../../sections/whyJoinUs/WhyJoinUs";
import { TemplateLinks } from "../../sections/templateLinks/TemplateLinks";
import { Investors } from "../../sections/investors/Investors";
import { DistributionNews } from "../../sections/distributionNews/DistributionNews";

export const SectionManager = () => {
  const location = useLocation();
  const { locale } = useParams();
  const { setPageData, pageData, lang, setLang } = useContext(StoreContext);
  const { page = {}, sectionPage } = pageData ?? {};
  const { metaTitle = {}, metaContent = {} } = page[lang] ?? {};

  const ref = useCallback((node) => {
    if (node !== null && node !== undefined) {
      window.scrollTo({
        top: node.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    getPageData(location.pathname)
      .then((res) => {
        setPageData({
          page: res.data.infoPage ?? {},
          sectionPage: res.data.page ?? [],
        });
      })
      .catch((err) => {
        console.error(err);
        setPageData({
          page: { slug: "not-found" },
          sectionPage: [{ template: "notFound" }],
        });
      });
  }, [location.pathname]);

  useEffect(() => {
    setLang(locale);
  }, [locale]);

  const generateSection = (section = {}, i) => {
    switch (section.template) {
      case "news":
        return (
          <LatestNews
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "howApply":
        return (
          <TextWithAction
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "investisseur":
        return (
          <Investors
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "sliderActivity":
        return (
          <Picture
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "listStudioActivity":
        return (
          <Carousel
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "textInfoActivity":
        return (
          <Text
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "newsActivity":
        return location.pathname.includes("/distribution") ? (
          <DistributionNews
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        ) : (
          <Mozaic
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "otherActivity":
        return (
          <TemplateLinks
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "sliderProduction":
        return (
          <Video
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "keyNumber":
        return (
          <DataTable
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "legalMention":
        return (
          <LegalMention
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );

      case "ourActivities":
        return (
          <PageList
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "ourCulture":
        return (
          <Frieze
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "theGroup":
        return (
          <OurStory
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "theGroupHero":
        return (
          <Hero
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "whyJoinUs":
        return (
          <WhyJoinUs
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "worldMap":
        return (
          <WorldMap
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      default:
        return null;
    }
  };

  const generateArticle = (section = {}, i) => {
    switch (section.title) {
      case "listNews":
        return (
          <Article
            section={section[lang]}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: lang }}>
        <title>{metaTitle.data ?? "Mediawan"}</title>
        <meta name="description" content={metaContent.data} />
      </Helmet>

      {page && (
        <main id={page.slug}>
          {sectionPage &&
            sectionPage.map((section, i) => generateSection(section, i))}
          {sectionPage &&
            sectionPage.map((section, i) => generateArticle(section, i))}
        </main>
      )}
    </>
  );
};
