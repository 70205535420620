import {
  forwardRef,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./distributionNews.scss";
import { motion, useAnimation } from "framer-motion";
import { StoreContext } from "../../store/store";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { Sound } from "../../components/Sound/Sound";
import { Link } from "react-router-dom";

export const DistributionNews = forwardRef((props, ref) => {
  const { section } = props;
  const { listBo = {} } = section ?? {};
  const { lang } = useContext(StoreContext);

  const controls = useAnimation();
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = useIsMobile();

  // Intersection observer

  useEffect(() => {
    if (isMobile) {
      setIsVisible(true);
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isMobile]);

  useEffect(() => {
    if (isVisible) {
      controls.start("visible");
    }
  }, [controls, isVisible]);

  const itemVariants = {
    hidden: (index) => ({
      opacity: 0,
      x: index % 2 === 0 ? "-10%" : "10%",
    }),
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  const videosRef = useRef([]);

  return (
    <section ref={sectionRef} className="distribution-news-section">
      <div className="distribution-news-container">
        <div className="distribution-news-wrapper">
          {listBo.data.map((news, index) => {
            const { media, shortContent, subTitle, btnUrl, listLogo } =
              news[lang] ?? {};

            return (
              <Fragment key={index}>
                <div className="distribution-news-news-divider" />

                <motion.div
                  className="distribution-news-news-container"
                  custom={index}
                  variants={itemVariants}
                  initial={isVisible ? "visible" : "hidden"}
                  animate={controls}
                  transition={{ delay: index * 0.1 }}
                >
                  <div className="distribution-news-news-companies-container">
                    {listLogo.data.map((company, index) => {
                      const { media } = company[lang] ?? {};

                      return (
                        <img
                          src={media.data.path}
                          alt={media.data.alt}
                          key={index}
                          className="distribution-news-news-companies-container-logo"
                        />
                      );
                    })}
                  </div>

                  <div
                    className={`distribution-news-news-container-wrapper ${
                      index % 2 !== 0 && !isMobile
                        ? "order-first"
                        : "order-last"
                    }`}
                  >
                    <div className="distribution-news-news-border distribution-news-news-border-top-left" />
                    <div className="distribution-news-news-border distribution-news-news-border-top-right" />
                    <div className="distribution-news-news-border distribution-news-news-border-bottom-left" />
                    <div className="distribution-news-news-border distribution-news-news-border-bottom-right" />

                    <div className="distribution-news-news-companies-container">
                      {listLogo.data.map((company, index) => {
                        const { media } = company[lang] ?? {};

                        return (
                          <img
                            src={media.data.path}
                            alt={media.data.alt}
                            key={index}
                            className="distribution-news-news-companies-container-logo"
                          />
                        );
                      })}
                    </div>

                    <div
                      className="distribution-news-news-description"
                      dangerouslySetInnerHTML={{ __html: shortContent.data }}
                    />

                    <Link
                      to={btnUrl.data}
                      target="_blank"
                      className="distribution-news-news-link"
                    >
                      {subTitle.data}
                    </Link>
                  </div>

                  {media.data && (
                    <div className="distribution-news-news-image-container">
                      {media.data.mimeType.startsWith("video/") ? (
                        <>
                          <video
                            ref={(el) => (videosRef.current[index] = el)}
                            src={media.data.path}
                            alt={media.data.alt}
                            autoPlay
                            loop
                            muted
                            width="100%"
                            draggable="false"
                            playsInline
                            className={`distribution-news-news-image ${
                              index % 2 !== 0 && !isMobile
                                ? "order-last"
                                : "order-first"
                            }`}
                          />
                          <div className="sound-container">
                            <Sound videoRef={videosRef} index={index} />
                          </div>
                        </>
                      ) : (
                        <img
                          src={media.data.path}
                          alt={media.data.alt}
                          className={`distribution-news-news-image ${
                            index % 2 === 0 ? "order-last" : "order-first"
                          }`}
                          draggable="false"
                        />
                      )}
                    </div>
                  )}
                </motion.div>
              </Fragment>
            );
          })}
        </div>
      </div>
    </section>
  );
});
