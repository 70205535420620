import { forwardRef } from "react";
import "./whyJoinUs.scss";

export const WhyJoinUs = forwardRef((props, ref) => {
  const { section } = props;
  const { content, media, title } = section;

  return (
    <section className="why-join-us-section">
      <img
        src={media.data?.path}
        alt={media.data?.alt}
        className="why-join-us-image"
      />

      <div className="why-join-us-content">
        <span className="why-join-us-title">{title.data}</span>
        <div
          className="why-join-us-description"
          dangerouslySetInnerHTML={{ __html: content.data }}
        />
      </div>
    </section>
  );
});
