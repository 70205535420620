import "./buttonDown.scss";
import { Link } from "react-router-dom";

export const ButtonDown = (props) => {
  const { to, color, onClick } = props;

  const colorClass = color === "dark" ? "dark" : "";

  return (
    <Link to={to}>
      <img
        src={`/icon/scroll${colorClass ? `-${colorClass}` : ""}.svg`}
        alt="down"
        onClick={onClick}
        className="button-down-icon"
      />
    </Link>
  );
};
