import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.scss";
import { StoreContext } from "../../store/store";
import { Lang } from "./Lang";
import { Burger } from "./Burger";

export const Header = () => {
  const { menusData, lang } = useContext(StoreContext);
  const { header: headerData = {}, footer: footerData = {} } = menusData ?? {};
  const { info = {}, langue = {}, menu = [] } = headerData;
  const { info: infoFooter = {} } = footerData;
  const {
    facebook = {},
    instagram = {},
    linkedin = {},
    twitter = {},
    youtube = {},
  } = infoFooter;
  const { logo_m = {}, logo_mediawan = {} } = info;

  const menuFormated = menu.map((item) => {
    let slug;
    let title;

    if (!item[lang]) {
      slug = "";
      title = "";
    } else {
      slug = item[lang].slug;
      title = item[lang].title;
    }

    return {
      slug,
      title,
      externalLink: item.externalLink,
      url: item.url,
      id: item.id,
      idPage: item.idPage,
    };
  });
  const generateLangList = () => {
    let list = [];

    for (const [key, value] of Object.entries(langue)) {
      if (value.data) list.push(key);
    }

    return list;
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    if (!isMenuOpen) {
      setScrollPosition(window.scrollY);
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflowY = "hidden";
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollPosition}px`;
    } else {
      document.body.style.overflowY = "unset";
      document.body.style.position = "unset";
      document.body.style.top = "unset";
      window.scrollTo(0, scrollPosition);
    }
  }, [isMenuOpen, scrollPosition]);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    headerData && (
      <>
        <header className="header-component">
          <Link to={`/${lang}/`} className="header-logo">
            <img
              src={logo_m.data?.path}
              alt={logo_m.data?.alt}
              className="header-logo-symbol"
            />
          </Link>

          <div className="header-border"></div>

          <div className="header-menu">
            <div className="header-menu-left">
              {menuFormated &&
                menuFormated.slice(0, 2).map((item, index) => (
                  <Link to={`/${lang}/${item.slug}`} key={index}>
                    <div className="header-menu-element">{item.title}</div>
                  </Link>
                ))}
            </div>

            <Link to={`/${lang}/`} className="header-menu-logo-wrapper">
              <img
                src={logo_mediawan.data?.path}
                alt={logo_mediawan.data?.alt}
                className="header-menu-logo"
              />
            </Link>

            <div className="header-menu-right">
              {menuFormated &&
                menuFormated.slice(2).map((item, index) => (
                  <Link to={`/${lang}/${item.slug}`} key={index}>
                    <div className="header-menu-element">{item.title}</div>
                  </Link>
                ))}
            </div>
          </div>

          <div className="header-border"></div>

          <Lang listLang={generateLangList()} />
        </header>

        <header className="header-component-mobile">
          <div className="header-component-mobile-header">
            <Lang listLang={generateLangList()} />

            <Link to={`/${lang}/`} className="header-menu-logo-wrapper">
              <img
                src={logo_mediawan.data?.path}
                alt={logo_mediawan.data?.alt}
                className="header-menu-logo"
              />
            </Link>

            <Burger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
          </div>
        </header>

        <div
          className={`header-component-mobile-menu ${isMenuOpen ? "open" : ""}`}
        >
          <div className="header-component-mobile-menu-header">
            <Link
              to={`/${lang}/`}
              className="header-component-mobile-menu-logo-wrapper"
            >
              <img
                src={logo_m.data?.path}
                alt={logo_m.data?.alt}
                className="header-component-mobile-menu-logo"
              />
            </Link>

            <Burger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
          </div>

          <div className="header-component-mobile-menu-element">
            <div className="header-component-mobile-menu-element-links">
              {menuFormated &&
                menuFormated.map((item, index) => (
                  <Link
                    to={`/${lang}/${item.slug}`}
                    key={index}
                    className="header-component-mobile-menu-element-link"
                  >
                    {item.title}
                  </Link>
                ))}
            </div>

            <div className="header-component-mobile-menu-element-lower">
              <div className="header-component-mobile-menu-element-lower-socials">
                <Link to={twitter.data} target="_blank">
                  <img
                    src="/newIcon/x.svg"
                    alt="twitter icon"
                    className="header-component-mobile-menu-element-lower-socials-icon"
                  />
                </Link>
                <Link to={facebook.data} target="_blank">
                  <img
                    src="/newIcon/facebook.svg"
                    alt="facebook icon"
                    className="header-component-mobile-menu-element-lower-socials-icon"
                  />
                </Link>
                <Link to={instagram.data} target="_blank">
                  <img
                    src="/icon/iconInstagram.svg"
                    alt="instagram icon"
                    className="header-component-mobile-menu-element-lower-socials-icon"
                  />
                </Link>
                <Link to={youtube.data} target="_blank">
                  <img
                    src="/newIcon/youtube.svg"
                    alt="youtube icon"
                    className="header-component-mobile-menu-element-lower-socials-icon"
                  />
                </Link>
                <Link to={linkedin.data} target="_blank">
                  <img
                    src="/icon/iconLinkedin.svg"
                    alt="linkedin icon"
                    className="header-component-mobile-menu-element-lower-socials-icon"
                  />
                </Link>
              </div>

              <div className="header-component-mobile-menu-element-lower-border" />

              <Lang listLang={generateLangList()} />
            </div>

            <div className="header-component-mobile-menu-element-close">
              <span
                onClick={toggleMenu}
                className="header-component-mobile-menu-element-close-button"
              >
                Close
              </span>
            </div>
          </div>
        </div>
      </>
    )
  );
};
