import { forwardRef } from "react";
import "./textWithAction.scss";
import { ButtonPlus } from "../../components/ButtonPlus/ButtonPlus";

export const TextWithAction = forwardRef((props, ref) => {
  const { section } = props;
  const { content, title, button } = section;

  console.log(button);

  return (
    <section className="text-with-action-section">
      <span className="text-with-action-title">{title.data}</span>
      <div
        className="text-with-action-description"
        dangerouslySetInnerHTML={{ __html: content.data }}
      />
      <ButtonPlus external to={button.url.data} />
    </section>
  );
});
